import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { PLAN_PREE, PLAN_PREMIUM } from '@core/utils/plan'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'created_on', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchBlog = (ctx, callback) => {
    store
      .dispatch('blog/fetchBlog', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalElements.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Đã xảy ra lỗi khi lấy dữ liệu',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // const fetchReadingPremium = (ctx, callback) => {
  //   store
  //     .dispatch('test-reading/fetchReadingPremium', {
  //       q: searchQuery.value,
  //       perPage: perPage.value,
  //       page: currentPage.value,
  //       sortBy: sortBy.value,
  //       sortDesc: isSortDirDesc.value,
  //       plan: PLAN_PREMIUM,
  //       status: statusFilter.value,
  //     })
  //     .then(response => {
  //       const { list, total } = response.data
  //       callback(list)
  //       totalElements.value = total
  //     })
  //     .catch(() => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Đã xảy ra lỗi khi lấy dữ liệu',
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //     })
  // }

  const resolveTestStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'draft') return 'secondary'
    return 'primary'
  }

  return {
    fetchBlog,
    // fetchReadingPremium,
    tableColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    resolveTestStatusVariant,
    refetchData,

    // Extra Filters
    planFilter,
    statusFilter,
  }
}
