<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              variant="primary"
            >
              <span class="text-nowrap">Thêm mới</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refListTable"
      class="position-relative"
      :items="fetchBlog"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Không tìm thấy bản ghi nào"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: title -->
      <template #cell(title)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="48"
              :src="data.item.avatar_url"
              :text="avatarText(data.item.author)"
              :to="{ name: 'users-view', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'blog-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.title }}
          </b-link>
          <small class="text-muted">@{{ data.item.author }}</small>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Chi tiết</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'users-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Chỉnh sửa</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Xóa</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BPagination,
  BButton,
  BTable,
  BDropdownItem,
  BDropdown,
} from 'bootstrap-vue'
import { avatarText, kFormatter } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
import useBlogList from './useBlogList'
import blogStoreModule from './blogStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BButton,
    BImg,
    BPagination,
    BTable,
    BDropdownItem,
    BDropdown,
    vSelect,
  },
  data() {
    return {
      search_query: '',
      blogList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
    }
  },
  created() {
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
  setup() {
    const BLOG_STORE_MODULE_NAME = 'blog'

    // Register module
    if (!store.hasModule(BLOG_STORE_MODULE_NAME)) store.registerModule(BLOG_STORE_MODULE_NAME, blogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BLOG_STORE_MODULE_NAME)) store.unregisterModule(BLOG_STORE_MODULE_NAME)
    })
    const {
      perPage, currentPage, perPageOptions, searchQuery, fetchBlog, tableColumns, sortBy, isSortDirDesc,
      totalElements,
      dataMeta,
    } = useBlogList()
    return {
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      fetchBlog,
      tableColumns,
      sortBy,
      isSortDirDesc,
      totalElements,
      dataMeta,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
